import React, { Component } from 'react';
import { Button, Form, Spinner } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const my_swal = withReactContent(Swal);

export default class FormUserInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formulario_data: props.formulario_data,
            next_page: props.next_page,
            show_button_spinner: false
        }
    }

    handleUserInfoChange = (evt, field) => {
        let state = this.state;
        state.formulario_data.user_info[field] = evt.target.value;
        this.setState({ state })
    }

    handleRepresentativeChange = () => {
        let state = this.state;
        state.formulario_data.user_info.has_representative = !state.formulario_data.user_info.has_representative;
        this.setState({ state })
    }

    handleDocumentoChange = (evt, doc_type) => {
        const documento = evt.target.files[0]
        const fileExtension = documento.name.split(".").at(-1)
        const allowedFileTypes = ["jpg", "png", "JPG", "PNG", "pdf", "PDF"]
        const maximumSize = 5 * 1024 * 1024
        if (!allowedFileTypes.includes(fileExtension)) {
            my_swal.fire(
                <p>Tipo de archivo no válido</p>,
                "Por favor seleccione un archivo en formato JPG, PNG o PDF de no más de 5MB.",
                'error',
            ).then(() => {
            }
            )
        } else if (documento.size >= maximumSize) {
            my_swal.fire(
                <p>Tamaño máximo excedido</p>,
                "Por favor seleccione un archivo en formato JPG, PNG o PDF de no más de 5MB.",
                'error',
            ).then(() => {
            }
            )
        } else {
            let state = this.state
            state.formulario_data.user_info[doc_type] = evt.target.files[0]
            this.setState({ state })
            this.upload_attachment(doc_type);
        }
    }

    is_siguiente_button_disabled() {
        let button_disabled = false;
        const obligatory_fields = ["nombre", "email"]
        for (const index in obligatory_fields) {
            for (const key in this.state.formulario_data.user_info) {
                if (key === obligatory_fields[index] && this.state.formulario_data.user_info[key].length <= 0) {
                    button_disabled = true;
                    break;
                }
            }
            if (button_disabled === true) {
                break;
            }
        }
        if (!button_disabled && this.state.formulario_data.user_info.has_representative) {
            const obligatory_representative_fields = ["rep_nombre", "rep_email"]
            for (const index in obligatory_representative_fields) {
                for (const key in this.state.formulario_data.user_info) {
                    if (key === obligatory_representative_fields[index] && this.state.formulario_data.user_info[key].length <= 0) {
                        button_disabled = true;
                        break;
                    }
                }
                if (button_disabled === true) {
                    break;
                }
            }
        }
        return button_disabled
    }

    async upload_attachment(doc_type) {
        this.setState({ show_button_spinner: true })
        const id_company = this.state.formulario_data.company_info.id_company;
        const documento = this.state.formulario_data.user_info[doc_type]
        const url = window.api_base_url + 'derechos/UploadAttachmentFormularioServlet';
        let file_url = "";
        let fd = new FormData();
        const fileName = documento.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\[|\]|\(|\)|\{|\}|\¿|\?|\¡|\!|\,|\;/g, "").replace(/ /g, "_");
        fd.append('file', documento);
        fd.append('fileName', fileName);
        fd.append('folderS3Bucket', 'AttachmentsFormulario');
        fd.append('idCompany', id_company);
        const response = await fetch(url, {
            mode: 'cors',
            method: 'POST',
            body: fd,
        });
        if (response.status === 200) {
            const jsonData = await response.json();
            file_url = jsonData.attachmentUrl;
            let state = this.state
            if (doc_type === "doc_user") {
                state.formulario_data.user_info.url_documento = file_url;
            } else {
                state.formulario_data.user_info.rep_url_documento = file_url;
            }
            this.setState({ state })
        } else {
            my_swal.fire(
                <p>Error subida documento</p>,
                "Error al enviar el documento adjunto.",
                'error',
            )
        }
        this.setState({ show_button_spinner: false })
    }

    check_and_next_page = (evt, doc_type) => {
        const email_is_valid = function (email) {
            return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
        }
        /*const dni_is_valid = function (dni) {
            const regex_dni = /^\d{8}[a-zA-Z]$/;
            let valid_dni = false
            if (regex_dni.test(dni) == true) {
                const cifras = dni.slice(0, -1);
                const letra = dni.slice(-1);
                const pos_letra = cifras % 23;
                const letra_check = 'TRWAGMYFPDXBNJZSQVHLCKET'[pos_letra];
                if (letra_check == letra.toUpperCase())
                    valid_dni = true
            }
            return valid_dni;
        }*/
        const { formulario_data, next_page } = this.state;
        const { user_info } = formulario_data;
        if (!email_is_valid(user_info.email)) {
            my_swal.fire(
                <p>Email no válido</p>,
                "Por favor introduzca un email correcto.",
                "error"
            )
            return -1
        }
        if (user_info.has_representative) {
            if (!email_is_valid(user_info.rep_email)) {
                my_swal.fire(
                    <p>Email del representate no válido</p>,
                    "Por favor introduzca un email correcto para el representante.",
                    "error"
                )
                return -1
            }
        }
        next_page(evt, formulario_data)
    }

    render() {
        const { formulario_data, show_button_spinner } = this.state;
        const { company_info } = formulario_data;
        const { user_info } = formulario_data;
        return (
            <div>
                <h1 className="title">{company_info.company_name}</h1>
                <div className='p-5 rounded'>
                    <p>Necesitamos tus datos de identificación:</p>
                    <Form onSubmit={e => { e.preventDefault(); }}>
                        <p> <input type="text" placeholder="Nombre y apellidos *" value={user_info.nombre} onChange={(evt) => this.handleUserInfoChange(evt, "nombre")} style={{ width: "100%" }} /></p>
                        <p> <input type="text" placeholder="Documento de identidad del usuario" value={user_info.dni} onChange={(evt) => this.handleUserInfoChange(evt, "dni")} style={{ width: "100%" }} /></p>
                        <p> <input type="text" placeholder="Email *" value={user_info.email} onChange={(evt) => this.handleUserInfoChange(evt, "email")} style={{ width: "100%" }} /></p>
                        <p> <input type="text" placeholder="Calle" value={user_info.calle} onChange={(evt) => this.handleUserInfoChange(evt, "calle")} style={{ width: "81%" }} />
                            <input type="text" placeholder="Número" value={user_info.numero} onChange={(evt) => this.handleUserInfoChange(evt, "numero")} style={{ 'marginLeft': '1%', width: "18%" }} />
                        </p>
                        <p> <input type="text" placeholder="Localidad" value={user_info.localidad} onChange={(evt) => this.handleUserInfoChange(evt, "localidad")} style={{ width: "44.5%" }} />
                            <input type="text" placeholder="Provincia" value={user_info.provincia} onChange={(evt) => this.handleUserInfoChange(evt, "provincia")} style={{ 'marginLeft': '1%', width: "44.5%" }} />
                            <input type="text" maxLength="5" placeholder="CP" value={user_info.cp} onChange={(evt) => this.handleUserInfoChange(evt, "cp")} style={{ 'marginLeft': '1%', width: "9%" }} />
                        </p>
                        <p> <input type="text" maxLength="20" placeholder="Teléfono" value={user_info.telefono} onChange={(evt) => this.handleUserInfoChange(evt, "telefono")} style={{ width: "100%" }} /></p>
                        <p>Documento de identidad del usuario <i>(imagen o PDF)</i> <em>opcional</em>:</p>
                        <p><label className="btn btn-w-m btn-primary">Examinar...<input accept="image/*, application/pdf" style={{ display: "none" }} type="file" onChange={(evt) => this.handleDocumentoChange(evt, "doc_user")} /></label>
                            <label style={{ 'marginLeft': '1%' }}> {user_info.doc_user.name}</label>
                        </p>
                        <p><input type="checkbox" id="checkbox" checked={user_info.has_representative} onChange={this.handleRepresentativeChange} /> El usuario tiene representante.</p>
                        <a className={user_info.has_representative ? 'p-5 text-white rounded' : 'hidden'}>
                            <p>Datos del representante:</p>
                            <p> <input type="text" placeholder="Nombre o entidad *" value={user_info.rep_nombre} onChange={(evt) => this.handleUserInfoChange(evt, "rep_nombre")} style={{ width: "100%" }} /></p>
                            <p> <input type="text" placeholder="Documento de identidad del representante" value={user_info.rep_dni} onChange={(evt) => this.handleUserInfoChange(evt, "rep_dni")} style={{ width: "100%" }} /></p>
                            <p> <input type="text" placeholder="Email *" value={user_info.rep_email} onChange={(evt) => this.handleUserInfoChange(evt, "rep_email")} style={{ width: "100%" }} /></p>
                            <p> <input type="text" placeholder="Calle" value={user_info.rep_calle} onChange={(evt) => this.handleUserInfoChange(evt, "rep_calle")} style={{ width: "81%" }} />
                                <input type="text" placeholder="Número" value={user_info.rep_numero} onChange={(evt) => this.handleUserInfoChange(evt, "rep_numero")} style={{ 'marginLeft': '1%', width: "18%" }} />
                            </p>
                            <p> <input type="text" placeholder="Localidad" value={user_info.rep_localidad} onChange={(evt) => this.handleUserInfoChange(evt, "rep_localidad")} style={{ width: "44.5%" }} />
                                <input type="text" placeholder="Provincia" value={user_info.rep_provincia} onChange={(evt) => this.handleUserInfoChange(evt, "rep_provincia")} style={{ 'marginLeft': '1%', width: "44.5%" }} />
                                <input type="text" placeholder="CP" value={user_info.rep_cp} onChange={(evt) => this.handleUserInfoChange(evt, "rep_cp")} style={{ 'marginLeft': '1%', width: "9%" }} />
                            </p>
                            <p> <input type="text" maxLength="20" placeholder="Teléfono" value={user_info.rep_telefono} onChange={(evt) => this.handleUserInfoChange(evt, "rep_telefono")} style={{ width: "100%" }} /></p>
                            <p>Documento de identidad del representante <i>(imagen o PDF)</i> <em>opcional</em>:</p>
                            <p><label className="btn btn-w-m btn-primary">Examinar...<input accept="image/*, application/pdf" style={{ display: "none" }} type="file" onChange={(evt) => this.handleDocumentoChange(evt, "doc_representative")} /></label>
                                <label style={{ 'marginLeft': '1%' }}> {user_info.doc_representative.name}</label>
                            </p>
                        </a>
                    </Form>
                    <Button disabled={this.is_siguiente_button_disabled()}
                        onClick={this.check_and_next_page}
                        className="btn btn-w-m btn-primary">
                        <Spinner color='white' animation='border' hidden={!show_button_spinner} />
                        <a hidden={show_button_spinner}>Siguiente</a>
                    </Button>
                </div>
            </div >
        );
    }
}