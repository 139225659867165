import React from 'react';
import './css/App.css';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Formulario from './Formulario/Formulario';
import "react-loader-spinner";

window.url_redirect = 'https://datagestion.net';
//window.api_base_url = 'https://pre.datagestion.net/datagest/api/v1.0/'
window.api_base_url = 'https://plataforma.datagestion.net/datagest/api/v1.0/'
const my_swal = withReactContent(Swal);

export default class App extends React.Component {

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <Formulario />
          <small className="smallForm">Copyright Datagestión © 2015 - 2022</small>
        </header>
      </div >
    )
  }
}